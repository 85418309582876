<template>
  <div class="landing is-family-monospace">
    <section class="section mt-5">
      <div class="columns is-multiline is-vcentered is-gapless">
        <div class="column has-text-left">
          <h1 class="title headline mt-5" v-html="$t('landing.title')"/>
          <h2 class="subtitle is-4 is-size-5-mobile mt-5">{{ $t('landing.subtitle') }}</h2>
          <div class="has-text-centered mt-5">
            <b-button :size="true ? 'is-medium' : 'is-large'" type="is-primary" class="is-rounded mt-5 pr-5 pl-5" @click.native="focusSports">{{ $t('landing.choose_sport') }}</b-button>
          </div>
          <br/>
          <div class="has-text-centered is-inline-block greyed faded mt-2" v-if="$root.isTablet()">
              <p class="is-size-6">{{ $t('landing.compatible') }}</p>
              <p class="mt-3">
                <img class="mr-5 ml-5" src="~@/assets/syncs/GarminConnectSmall.png"/>
                <img class="mr-5 ml-5" src="~@/assets/syncs/poweredByStravaSmall.png"/>
              </p>
            <!--<div class="greyed faded">
              <p class="">{{ $t('landing.partners') }}</p>
              <p>
                <img class="mr-5 ml-5" src="~@/assets/landing/partners/FrenchTechBordeauxSmall.png"/>
                <img class="mr-5 ml-5" src="~@/assets/landing/partners/shraceSmall.png"/>
                <img class="mr-5 ml-5" src="~@/assets/landing/partners/tahitiSmall.png"/>
              </p>
            </div>-->
          </div>
        </div>

        <div class="column">
          <img class="" src="~@/assets/landing/Cover2.jpg"/>

          <!--<img class="" src="~@/assets/landing/CoverImage.png"/>-->
        </div>
      </div>

      <div class="partners" v-if="$root.isMobile()">
        <div class="greyed faded">
          <p>{{ $t('landing.compatible') }}</p>
          <p>
            <img class="mr-5 ml-5" src="~@/assets/syncs/GarminConnectSmall.png"/>
            <img class="mr-5 ml-5" src="~@/assets/syncs/poweredByStravaSmall.png"/>
          </p>
        </div>
      </div>
    </section>



    <section class="section container mt-6">
      <h1 class="title is-1 is-size-2-mobile has-text-secondary" v-html="$t('landing.features')"/>

      <div class="columns is-multiline mt-5">
        <div class="column is-flex is-flex-direction-column">
          <img class="is-block" src="~@/assets/landing/easy2.png"/>
          <div class="has-background-primary has-text-white p-2 is-flex-grow-1">
            <h2 class="title is-3 has-text-white m-0">{{ $t('landing.simple') }}</h2>
            <p class="has-text-left p-2">{{ $t('landing.simple_text') }}</p>
          </div>
        </div>
        <div class="column is-flex is-flex-direction-column">
          <img class="is-block" src="~@/assets/landing/performance2.png"/>
          <div class="has-background-primary has-text-white p-2 is-flex-grow-1">
            <h2 class="title is-3 has-text-white">{{ $t('landing.customized') }}</h2>
            <p class="has-text-left p-2">{{ $t('landing.customized_text') }}</p>
          </div>
        </div>
        <div class="column is-flex is-flex-direction-column">
          <img class="is-block" src="~@/assets/landing/Cyclist5.png"/>
          <div class="has-background-primary has-text-white p-2 is-flex-grow-1">
            <h2 class="title is-3 has-text-white">{{ $t('landing.efficient') }}</h2>
            <p class="has-text-left p-2">{{ $t('landing.efficient_text') }}</p>
          </div>
        </div>
      </div>

      <b-button tag="router-link" to="/signup" :size="$root.isMobile() ? 'is-medium' : 'is-large'" type="is-primary" class="is-rounded mt-6">{{ $t('landing.try_free') }}</b-button>

    </section>



    <section class="section container mt-6">
      <h1 class="title is-1 is-size-2-mobile has-text-secondary is-8-desktop is-4-tablet">{{ $t('landing.how_it_work') }}</h1>

      <div class="columns is-multiline has-text-left">
        <div class="column">
          <div class="box is-flex is-flex-direction-column h-100p">
            <h2 class="title is-3 is-size-4-mobile has-text-secondary">{{ $t('landing.w1') }}</h2>
            <img src="~@/assets/landing/howitwork1.png"/>
            <span class="is-flex-grow-1"/>
          </div>
        </div>
        <div class="column">
          <div class="box is-flex is-flex-direction-column h-100p">
            <h2 class="title is-3 is-size-4-mobile has-text-secondary">{{ $t('landing.w2') }}</h2>
            <img src="~@/assets/landing/howitwork2.png"/>
            <span class="is-flex-grow-1"/>
          </div>
        </div>
      </div>
      <div class="columns is-multiline has-text-left ">
        <div class="column">
          <div class="box is-flex is-flex-direction-column h-100p">
            <h2 class="title is-3 is-size-4-mobile has-text-secondary">{{ $t('landing.w3') }}</h2>
            <img src="~@/assets/landing/howitwork3.png"/>
            <span class="is-flex-grow-1"/>
          </div>
        </div>
        <div class="column">
          <div class="box is-flex is-flex-direction-column h-100p">
            <h2 class="title is-3 is-size-4-mobile has-text-secondary">{{ $t('landing.w4') }}</h2>
            <img src="~@/assets/landing/howitwork4.png"/>
            <span class="is-flex-grow-1"/>
          </div>
        </div>
      </div>
    </section>


    <section class="section container" :class="$root.isMobile() ? 'mt-1' : 'mt-7'" ref="chooseSport">
      <h1 class="title is-1 is-size-2-mobile has-text-centered" v-html="$t('landing.you_are')"/>
      <div class="columns is-mobile is-multiline mt-6">
        <div class="column is-half-mobile">
          <div class="sport-button">
            <router-link to="/choose_plan">
              <img src="~@/assets/landing/Cycling.png"/>
              <h3 class="sport-button-text title is-3 is-size-5-touch has-text-white">{{ $t('landing.cycling') }}</h3>
            </router-link>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button">
            <router-link to="/sport_coming_soon?sport=swimming">
              <img src="~@/assets/landing/Swimming.png"/>
              <div class="sport-button-text">
                <h3 class="title is-3 is-size-5-touch has-text-white">{{ $t('landing.swimming') }}</h3>
                <!--<h4 class="subtitle is-6 has-text-white">{{ $t('landing.coming_soon') }}</h4>-->
              </div>
            </router-link>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button">
            <router-link to="/sport_coming_soon?sport=running">
              <img src="~@/assets/landing/Running.png"/>
              <div class="sport-button-text">
                <h3 class="title is-3 is-size-5-touch has-text-white">{{ $t('landing.running') }}</h3>
                <!--<h4 class="subtitle is-6 has-text-white">{{ $t('landing.coming_soon') }}</h4>-->
              </div>
            </router-link>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button">
            <router-link to="/sport_coming_soon?sport=triathlon">
              <img src="~@/assets/landing/Triathlon.png"/>
              <div class="sport-button-text">
                <h3 class="title is-3 is-size-5-touch has-text-white">{{ $t('landing.triathlon') }}</h3>
                <!--<h4 class="subtitle is-6 has-text-white">{{ $t('landing.coming_soon') }}</h4>-->
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>



    <FeaturesList title="landing.our_offers" showSignups/>



    <section class="section container">
      <Testimonials/>
    </section>

    <section class="section container">
      <div class="columns is-vcentered">
        <div class="column has-text-left">
          <h3 class="title is-4 has-text-primary mb-0">{{ $t('landing.any_questions')}}</h3>
          <h1 class="title is-1 is-size-3-mobile has-text-secondary mt-1">{{ $t('landing.demo') }}</h1>
          <p class="mb-5" v-html="$t('landing.demo_text')"/>

          <AddMail buttonType="is-primary" :buttonText="$t('general.send')" list="demo"/>
        </div>

        <div class="column">
          <img src="~@/assets/landing/Demo.png"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FeaturesList from './FeaturesList.vue';
import Testimonials from './Testimonials.vue';
import AddMail from './AddMail.vue';

export default{
  name: 'Landing',
  data(){
    return {

    };
  },
  methods: {
    focusSports(){
      this.$refs.chooseSport.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
  metaInfo(){
    return {
      link: this.$getAlternates(''),
    };
  },
  beforeMount(){  //If user is logged in, redirect ASAP to the dashboard
    if(this.$store.getters.isLoggedIn)
      this.$router.push('/dashboard');
  },
  components: {
    FeaturesList,
    Testimonials,
    AddMail,
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans:ital,wght@1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@1,8..144,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.headline{
  font-family: 'Caveat', cursive;
  /*font-family: 'Roboto Serif', serif;*/
  text-transform: uppercase;
  font-size: 72px !important;
  @media only screen and (max-width: $tablet){ font-size: 40px !important;}
}
.button.is-superlarge{
  font-size: 1.75rem;
}

.greyed{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
.partners{
}
.faded{
  opacity: 0.75;
}


.sport-button{
  position: relative;
  text-align: center;
  color: white;
  transition: all .2s ease-in-out;
}

.sport-button:hover{
  filter: opacity(0.7);
  transform: scale(1.1);
}
.sport-button-text{
  left: 0;
  position:absolute;
  text-align:center;
  bottom: 30px;
  width: 100%;
  word-break: none !important;
}


.app1{
  position: absolute;
  top: 0;
  right: 0;
}
.app2{
  position: absolute;
  bottom: 0;
  left: 0;
}

.card{
  border-radius: 15px;
  width: 474px;
  z-index: 1;
}
.card .card-header{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card1{
  margin-left: 5%;
}
.card2{
  margin-right: 15%;
  margin-left: auto;
  margin-top: 100px;
}
.card3{
  margin-left: 20%;
  margin-top: 100px;
}
.card4{
  margin-right: 5%;
  margin-left: auto;
  margin-top: 100px;
  margin-bottom: 150px;
}

.white-border{
  border: solid white 1px;
}
</style>
